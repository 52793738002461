import React from "react";
import { CSSTransition } from "react-transition-group";

import closeIcon from "../../../images/icons/close.svg";
import menuIcon from "../../../images/icons/hamburger-menu.svg";
import logo from "../../../images/oroboro-logo.svg";

import * as Styled from "./styled";

// MARK: Render

interface NavBarProps {
  logoSubtitle: string | null;
  logoSubtitlePath: string | null;
  isVisible: boolean;
  isCloseButtonVisible?: boolean;
  isAbsolutelyPositioned?: boolean;
  isColorInverted?: boolean;
  onClickActionButton: () => void;
}

const NavBar: React.FC<NavBarProps> = ({
  logoSubtitle,
  logoSubtitlePath,
  isVisible,
  isCloseButtonVisible,
  isAbsolutelyPositioned,
  isColorInverted,
  onClickActionButton
}) => (
  <CSSTransition
    in={isVisible}
    classNames={Styled.transitionName}
    timeout={Styled.transitionDuration}
    unmountOnExit={true}
  >
    <Styled.Container
      isAbsolutelyPositioned={isAbsolutelyPositioned}
      isColorInverted={isColorInverted}
    >
      <Styled.GlobalStyles />

      <Styled.LogoContainer>
        <Styled.Logo to="/">
          <img src={logo} alt="Oroboro logo" />
        </Styled.Logo>
        {logoSubtitle && logoSubtitlePath && (
          <Styled.LogoSubtitle to={logoSubtitlePath}>
            <span>&nbsp;/</span> {logoSubtitle}
          </Styled.LogoSubtitle>
        )}
      </Styled.LogoContainer>
      {isCloseButtonVisible ? (
        <Styled.CloseButton onClick={onClickActionButton}>
          <img src={closeIcon} alt="Menu icon" />
        </Styled.CloseButton>
      ) : (
        <Styled.MenuButton onClick={onClickActionButton}>
          <img src={menuIcon} alt="Menu icon" />
        </Styled.MenuButton>
      )}
    </Styled.Container>
  </CSSTransition>
);

export default NavBar;
