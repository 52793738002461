/**
 * This file imports the styled components modules, sets the Theme type on them,
 * and then re-exports them for use.
 *
 * Learn more here:
 * https://www.styled-components.com/docs/api#define-a-theme-interface
 */

import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";

import { AppTheme } from "./";

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as ThemedStyledComponentsModule<AppTheme>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
