import * as color from "./color";
import * as content from "./content";
import * as font from "./font";
import * as media from "./media";

const theme = {
  font,
  color,
  media,
  content
};

export type AppTheme = typeof theme;

export default theme;
