import { Location } from "@reach/router";
import React from "react";

import Menu from "./menu";
import NavBar from "./navBar";

interface SubtitleMapItem {
  path: string;
  title: string;
}

interface NavigationProps {
  isNavBarFixed?: boolean;
  isNavBarColorInverted?: boolean;
}

interface NavigationState {
  isMenuBarOpen: boolean;
}

export default class Navigation extends React.Component<
  NavigationProps,
  NavigationState
> {
  // MARK: State

  public state: NavigationState = {
    isMenuBarOpen: false
  };

  // MARK: Constructor

  constructor(props: NavigationProps) {
    super(props);

    this.toggleMenuBar = this.toggleMenuBar.bind(this);
  }

  // MARK: Render

  public render() {
    return (
      <Location>
        {({ location }) => {
          const subtitleItem = this.getCurrentPageSubtitle(location.pathname);
          const subtitle = subtitleItem ? subtitleItem.title : null;
          const subtitlePath = subtitleItem ? subtitleItem.path : null;

          return (
            <>
              {
                // > MARK: Menu bar
              }
              <Menu
                currentLocationPathname={location.pathname}
                isOpen={this.state.isMenuBarOpen}
                onClose={this.toggleMenuBar}
              />

              {
                // > MARK: Nav bar
              }
              <NavBar
                logoSubtitle={subtitle}
                logoSubtitlePath={subtitlePath}
                isVisible={!this.state.isMenuBarOpen}
                isAbsolutelyPositioned={this.props.isNavBarFixed}
                isColorInverted={this.props.isNavBarColorInverted}
                onClickActionButton={this.toggleMenuBar}
              />
            </>
          );
        }}
      </Location>
    );
  }

  // MARK: Helper methods

  private getCurrentPageSubtitle(pathname: string): SubtitleMapItem | null {
    const subtitleMap: { [subtitle: string]: SubtitleMapItem } = {
      art: {
        path: "/art",
        title: "art"
      },
      caseStudies: {
        path: "/case-studies",
        title: "case studies"
      }
    };

    const subtitleKey = Object.keys(subtitleMap).find(key => {
      const matches = pathname.match(subtitleMap[key].path);
      return matches ? matches.length > 0 : false;
    });

    return subtitleKey ? subtitleMap[subtitleKey] : null;
  }

  // MARK: State mutators

  private toggleMenuBar() {
    this.setState(({ isMenuBarOpen }) => ({ isMenuBarOpen: !isMenuBarOpen }));
  }
}
