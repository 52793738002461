import { css } from "styled-components";

class FontSize {
  constructor(public value: number, public unit: string) {}

  public get css() {
    return css`
      font-size: ${`${this.value}${this.unit}`};
    `;
  }

  public multipliedBy(ratio: number) {
    return new FontSize(this.value * ratio, this.unit);
  }

  public cssWithLineHeightRatio(lineHeightRatio: number = 1.5) {
    return css`
            ${this.css}
            line-height: ${`${this.value * lineHeightRatio}${this.unit}`};
        `;
  }
}

export const size = {
  xxLarge: new FontSize(3, "rem"),
  xLarge: new FontSize(2, "rem"),
  large: new FontSize(1.5, "rem"),
  mediumLarge: new FontSize(1.25, "rem"),
  medium: new FontSize(1.125, "rem"),
  normal: new FontSize(1, "rem"),
  small: new FontSize(0.75, "rem"),
  base: new FontSize(16, "px")
};

export const style = {
  workSans: css`
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
  `,
  raleway: css`
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
  `
};
