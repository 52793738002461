import { Link } from "gatsby";
import React from "react";
import * as Scroll from "react-scroll";
import { CSSTransition } from "react-transition-group";

import styled, { createGlobalStyle, css } from "../../../theme/styled";

import * as Typo from "../../typo";

import NavBar from "../navBar";

// MARK: Global CSS

const transitionName = "menubar";
const transitionDuration = 300;

// tslint:disable-next-line:no-unused-expression
export const GlobalStyles = createGlobalStyle`
  .${transitionName}-enter {
      opacity: 0.01;
      transform: translateY(-24px);
  }

  .${transitionName}-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity ${transitionDuration}ms ease-out, transform ${transitionDuration}ms ease-out;
  }

  .${transitionName}-exit {
      opacity: 1;
      transform: translateY(0);
  }

  .${transitionName}-exit-active {
      opacity: 0;
      transform: translateY(-24px);
      transition: opacity ${transitionDuration}ms ease-out, transform ${transitionDuration}ms ease-out;
  }
`;

// MARK: Components

const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.values.white};
  z-index: 7;
`;

const ContentContainer = styled.div`
  margin: 0 24px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ theme: localTheme }) => localTheme.media.tabletMin`
        min-width: 680px;
        max-width: calc(100vw - 80px);
        margin: 0 auto;
    `};

  ${({ theme: localTheme }) => localTheme.media.tabletLandscapeMin`
        min-width: 936px;
        max-width: calc(100vw - 80px);
    `};

  ${({ theme: localTheme }) => localTheme.media.desktopMin`
        width: calc(100vw - 160px);
        max-width: 1128px;
    `};
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const menuItemStyles = css`
  margin-top: 16px;
  transition: transform 0.24s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    opacity 0.24s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  user-select: none;
  text-decoration: none;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    cursor: pointer;
    transform: translateX(8px);
    opacity: 0.64;
  }

  ${({ theme: localTheme }) => localTheme.media.tabletMin`
        margin-top: 24px;
    `};
`;

const MenuItemAsScrollLink = styled(Scroll.Link)`
  ${menuItemStyles};
`;

const MenuItemAsLink = styled(Link)`
  ${menuItemStyles};
`;

const MenuItemTitle = styled(Typo.H1)``;

// MARK: Render

const renderMenuItem: React.FC<{
  title: string;
  path: string;
  isSeperatePage?: boolean;
  isAtHomePage?: boolean;
  onClick: () => void;
}> = ({ title, path, isSeperatePage, isAtHomePage, onClick }) => {
  const defaultScrollLinkSettings = {
    smooth: true,
    spy: true,
    hashSpy: true,
    offset: -40
  };

  const ItemContent: React.FC<{
    itemTitle: string;
  }> = ({ itemTitle }) => (
    <MenuItemTitle>
      <Typo.Greyed>/</Typo.Greyed> {itemTitle}
    </MenuItemTitle>
  );

  // > NOTE: Path check
  // Currently has to check whether current path is at home page or not
  // since scrolling animation requires the Scroll component

  if (isSeperatePage) {
    return (
      <MenuItemAsLink key={path} to={`/${path}`} onClick={onClick}>
        <ItemContent itemTitle={title} />
      </MenuItemAsLink>
    );
  }

  return isAtHomePage ? (
    <MenuItemAsScrollLink
      key={path}
      to={path}
      {...defaultScrollLinkSettings}
      onClick={onClick}
    >
      <ItemContent itemTitle={title} />
    </MenuItemAsScrollLink>
  ) : (
    <MenuItemAsLink key={path} to={`/#${path}`} onClick={onClick}>
      <ItemContent itemTitle={title} />
    </MenuItemAsLink>
  );
};

interface MenuProps {
  currentLocationPathname: string;
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({
  currentLocationPathname,
  isOpen,
  onClose
}) => {
  const items = [
    {
      title: "about us",
      path: "about"
    },
    {
      title: "our work",
      path: "case-studies",
      isSeperatePage: true
    },
    {
      title: "contact us",
      path: "contact"
    }
  ];

  const isAtHomePage = currentLocationPathname === "/";

  return (
    <CSSTransition
      in={isOpen}
      classNames={transitionName}
      timeout={transitionDuration}
      unmountOnExit={true}
    >
      <Layout>
        <GlobalStyles />

        <ContentContainer>
          <NavBar
            logoSubtitle={null}
            logoSubtitlePath={null}
            isVisible={true}
            isAbsolutelyPositioned={true}
            isCloseButtonVisible={true}
            onClickActionButton={onClose}
          />
          <MenuItemsContainer>
            {items.map(({ title, path, isSeperatePage }) =>
              renderMenuItem({
                title,
                path,
                onClick: onClose,
                isSeperatePage,
                isAtHomePage
              })
            )}
          </MenuItemsContainer>
        </ContentContainer>
      </Layout>
    </CSSTransition>
  );
};

export default Menu;
