import styled from "../theme/styled";

export const H1 = styled.h1`
    ${({ theme }) => theme.font.style.workSans}
    ${({ theme }) => theme.font.size.large.cssWithLineHeightRatio()}

    color: ${({ theme }) => theme.color.values.black};
    
    ${({ theme }) => theme.media.tabletMin`
        ${theme.font.size.xxLarge.cssWithLineHeightRatio()}
    `}
`;

export const H2 = styled.h2`
    ${({ theme }) => theme.font.style.workSans}
    ${({ theme }) => theme.font.size.large.cssWithLineHeightRatio()}

    color: ${({ theme }) => theme.color.values.black};
    
    ${({ theme }) => theme.media.tabletMin`
        ${theme.font.size.xLarge.cssWithLineHeightRatio()}
    `}
`;

export const H3 = styled.h3`
    ${({ theme }) => theme.font.style.workSans}
    ${({ theme }) => theme.font.size.medium.cssWithLineHeightRatio()}

    color: ${({ theme }) => theme.color.values.black};
    
    ${({ theme }) => theme.media.tabletMin`
        ${theme.font.size.mediumLarge.cssWithLineHeightRatio()}
    `}

    ${({ theme }) => theme.media.tabletLandscapeMin`
        ${theme.font.size.large.cssWithLineHeightRatio()}
    `}
`;

export const BodyRegular = styled.p`
    ${({ theme }) => theme.font.style.raleway}
    ${({ theme }) => theme.font.size.normal.cssWithLineHeightRatio(2)}

    color: ${({ theme }) => theme.color.values.black};

    ${({ theme }) => theme.media.tabletLandscapeMin`
        ${theme.font.size.medium.cssWithLineHeightRatio(2)}
    `}
`;

export const BodySmall = styled.p`
    ${({ theme }) => theme.font.style.raleway}
    ${({ theme }) => theme.font.size.small.cssWithLineHeightRatio()}

    color: ${({ theme }) => theme.color.values.black};
`;

export const ActionTitle = styled.span`
    ${({ theme }) => theme.font.style.workSans}
    ${({ theme }) => theme.font.size.normal.cssWithLineHeightRatio()}

    color: ${({ theme }) => theme.color.values.activeBlue};

    ${({ theme }) => theme.media.tabletLandscapeMin`
      ${theme.font.size.medium.cssWithLineHeightRatio()}
    `}
`;

export const Greyed = styled.span`
  color: ${({ theme }) => theme.color.values.grey};
`;
