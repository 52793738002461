import { css } from "./styled";

const sizes = {
  tabletPortraitMin: 680 + 48,
  tabletLandscapeMin: 936 + 80,
  desktopMin: 1128 + 160
};

export const tabletMin = (strings: TemplateStringsArray, ...args: any[]) => css`
  @media (min-width: ${sizes.tabletPortraitMin}px) {
    ${css(strings, args)};
  }
`;

export const tabletLandscapeMin = (
  strings: TemplateStringsArray,
  ...args: any[]
) => css`
  @media (min-width: ${sizes.tabletLandscapeMin}px) {
    ${css(strings, args)};
  }
`;

export const desktopMin = (
  strings: TemplateStringsArray,
  ...args: any[]
) => css`
  @media (min-width: ${sizes.desktopMin}px) {
    ${css(strings, args)};
  }
`;
