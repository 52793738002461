import { Link } from "gatsby";

import styled, { createGlobalStyle, css } from "../../../theme/styled";

// MARK: Global CSS

export const transitionName = "navbar";
export const transitionDuration = 300;

// tslint:disable-next-line:no-unused-expression
export const GlobalStyles = createGlobalStyle`
  .${transitionName}-enter {
    opacity: 0.01;
    transform: scale(0.9);
  }

  .${transitionName}-enter-active {
    opacity: 1;
        transform: scale(1);
        transition: opacity ${transitionDuration}ms ease-out, transform ${transitionDuration}ms ease-out;
  }

  .${transitionName}-exit {
    opacity: 1;
        transform: scale(1);
  }

  .${transitionName}-exit-active {
    opacity: 0;
        transform: scale(0.9);
        transition: opacity ${transitionDuration}ms ease-out, transform ${transitionDuration}ms ease-out;
  }
`;

// MARK: Components

export const Container = styled.nav<{
  isAbsolutelyPositioned?: boolean;
  isColorInverted?: boolean;
}>`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  filter: invert(${({ isColorInverted }) => (isColorInverted ? 1 : 0)});

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 42px;
  `};

  ${({ isAbsolutelyPositioned }) =>
    isAbsolutelyPositioned &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `};
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: center;
`;

export const Logo = styled(Link)`
  box-sizing: content-box;
  padding: 14px 0;
  margin: -14px 0;
  height: 16px;
  align-self: flex-end;
  position: relative;

  img {
    display: block;
    height: inherit;
  }

  ${({ theme }) => theme.media.tabletMin`
    height: 20px;
  `};
`;

export const LogoSubtitle = styled(Link)`
  height: 16px;
  line-height: 16px;
  font-size: 20px;
  ${({ theme }) => theme.font.style.raleway};
  text-decoration: none;

  color: ${({ theme }) => theme.color.values.grey};

  span {
    ${({ theme }) => theme.font.style.workSans};
  }

  ${({ theme }) => theme.media.tabletMin`
    line-height: 20px;
    font-size: 27px;
  `};
`;

export const MenuButton = styled.button`
  box-sizing: content-box;
  justify-self: flex-end;
  align-self: flex-end;
  padding: 18px;
  margin: -18px;
  height: 12px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &:hover {
    opacity: 0.8;
    transform: scale(0.95);
  }

  img {
    display: block;
    height: inherit;
  }

  ${({ theme }) => theme.media.tabletMin`
    height: 18px;
  `};
`;

export const CloseButton = styled.button`
  box-sizing: content-box;
  justify-self: flex-end;
  align-self: flex-end;
  padding: 18px;
  margin: -18px;
  height: 12px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &:hover {
    opacity: 0.8;
    transform: scale(0.95);
  }

  img {
    display: block;
    height: inherit;
  }

  ${({ theme }) => theme.media.tabletMin`
    height: 18px;
  `};
`;
