import styled, { css } from "../../theme/styled";

export const FooterSection = styled.footer<{
  isAbsolutelyPositioned?: boolean;
}>`
  margin-bottom: 33px;

  ${({ isAbsolutelyPositioned }) =>
    isAbsolutelyPositioned &&
    css`
      position: absolute;
      bottom: 0;
    `};
`;

export const FooterCopyrightLabel = styled.small`
      ${({ theme: localTheme }) => localTheme.font.style.raleway}
      ${({ theme: localTheme }) =>
        localTheme.font.size.small.cssWithLineHeightRatio()}
  
      color: ${({ theme: localTheme }) => localTheme.color.values.grey};
  `;
