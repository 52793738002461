import { css } from "./styled";

export const defaultWidth = css`
  margin: 0 24px;

  ${({ theme }) => theme.media.tabletMin`
      min-width: 680px;
      max-width: calc(100vw - 80px);
      margin: 0 auto;
    `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
      min-width: 936px;
      max-width: calc(100vw - 80px);
    `};

  ${({ theme }) => theme.media.desktopMin`
      width: calc(100vw - 160px);
      max-width: 1128px;
    `};
`;
