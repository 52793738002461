import React from "react";

import * as Styled from "./styled";

interface FooterProps {
  isAbsolutelyPositioned?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isAbsolutelyPositioned }) => {
  return (
    <Styled.FooterSection isAbsolutelyPositioned={isAbsolutelyPositioned}>
      <Styled.FooterCopyrightLabel children="© 2018 Oroboro" />
    </Styled.FooterSection>
  );
};

export default Footer;
